import { P2PProviderType } from 'enums';
import { P2PProviderDto } from 'types';

const P2P_PROVIDER_CONFIG_VALUE = {
  url: '',
  credentials: {
    apiKey: '',
    apiToken: '',
    signatureKey: '',
    password: '',
    apiSecret: '',
    apiPublic: '',
    apiPrivate: '',
    host2host: '',
  },
  enabled: false,
  pollingEnabled: false,
  responseTimeout: null,
  expectedFee: 0,
  tradeMethods: [],
  assetCurrencyExchangeId: null,
};

export const P2P_PROVIDER_INITIAL_VALUE: P2PProviderDto = {
  name: '',
  type: P2PProviderType.InternalLike,
  withdrawalFee: 0,
  assetCurrencyId: '',
  fiatCurrencyId: '',
  currencyExchangeId: '',
  payinConfig: P2P_PROVIDER_CONFIG_VALUE,
  payoutConfig: P2P_PROVIDER_CONFIG_VALUE,
  payoutLimits: {
    limitSum: 0,
    limitCount: 0,
  },
};
