import { env } from 'config';
import { P2PProviderPayoutWebhook } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/p2p-providers-payout-webhooks`; // TODO: common path with payin

export const p2pProvidersPayoutWebhooksApi = {
  getForOrder: async (
    providerOrderId: string,
  ): Promise<P2PProviderPayoutWebhook[]> =>
    (await apiUtils.getWithQueryParams(`${url}/order/${providerOrderId}`)).data,
};
