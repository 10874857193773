import { Stack } from '@mui/material';
import { isNumber } from 'lodash';
import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DescriptionRow, Money, StylizedMoney } from 'components';
import { OrderType } from 'enums';
import { useCurrencies } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProvider } from 'types';

import { P2PProviderConfigDetails } from './P2PProviderConfigDetails';

type Props = {
  p2pProvider: P2PProvider;
};

export const P2PProviderDetails: React.FC<Props> = ({ p2pProvider }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });

  const { getDefaultAssetCurrency, getAssetCurrencyCode, getFiatCurrencyCode } =
    useCurrencies();

  const renderPayoutLimits = useCallback(() => {
    const limits = p2pProvider.payoutLimits;
    return (
      <DescriptionRow
        title={t('details.payout_limits.title')}
        value={
          <Fragment>
            <div className="tw-flex">
              <div className="tw-mr-2">{t('details.payout_limits.sum')}</div>
              <Money
                symbol
                value={limits.limitSum || 0}
                fiatCurrencyId={p2pProvider.fiatCurrencyId}
              />
            </div>
            <div className="tw-flex">
              <div className="tw-mr-2">{t('details.payout_limits.count')}</div>
              <div>{limits.limitCount || 0}</div>
            </div>
          </Fragment>
        }
      />
    );
  }, [p2pProvider, t]);

  return (
    <Stack direction="column" spacing={4}>
      <DescriptionRow title={t('details.id')} value={p2pProvider.id} canCopy />
      <DescriptionRow title={t('details.name')} value={p2pProvider.name} />
      <DescriptionRow
        title={t('details.type')}
        value={t(`types.${p2pProvider.type}`)}
      />
      <DescriptionRow
        title={t('details.assetCurrencyId')}
        value={getAssetCurrencyCode(p2pProvider.assetCurrencyId)}
      />
      <DescriptionRow
        title={t('details.fiatCurrencyId')}
        value={getFiatCurrencyCode(p2pProvider.fiatCurrencyId)}
      />

      <DescriptionRow
        title={t('details.withdrawal_fee')}
        value={
          isNumber(p2pProvider.withdrawalFee) && (
            <StylizedMoney
              value={p2pProvider.withdrawalFee}
              assetCurrencyId={getDefaultAssetCurrency()?.id}
              symbol
            />
          )
        }
      />

      <P2PProviderConfigDetails
        configType={OrderType.Payin}
        config={p2pProvider.payinConfig}
      />

      <P2PProviderConfigDetails
        configType={OrderType.Payout}
        config={p2pProvider.payoutConfig}
      />

      {renderPayoutLimits()}
    </Stack>
  );
};
