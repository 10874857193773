import {
  Code as CodeIcon,
  Abc as AbcIcon,
  ReceiptOutlined as ReceiptOutlinedIcon,
  SvgIconComponent,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AxiosError } from 'axios';
import { find, map } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { Link, generatePath } from 'react-router-dom';

import { banksApi, fiatCurrencyApi, paymentTypesApi } from 'api';
import { tradeMethodsApi } from 'api/trade-methods.api';
import {
  CloseFormikDialogData,
  CloseFormikDialogResult,
  CopyTextId,
  CrudPage,
  CrudTableActionType,
  DataGridColumnDefinition,
} from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { FilterDefinitionType, QueryKey } from 'enums';
import {
  useCurrencies,
  useMutation,
  usePartialQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, TradeMethod, TradeMethodDto } from 'types';
import { formUtils, requisitesUtils, validationUtils } from 'utils';

import { TradeMethodDetailsDialog } from './TradeMethodDetailsDialog';

type TradeMethodsFilters = {
  fiatCurrencyId: string;
  paymentTypeId: string;
  bankId: string;
};

export const TradeMethodsPage: React.FC = () => {
  const { isAdmin, isTechOperator } = useUser();
  const queryClient = useQueryClient();
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { paymentTypes, banks } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.trade_methods',
  });
  const queryResultPaymentTypes = useQuery(
    QueryKey.PaymentTypes,
    async ({ signal }) => {
      const paymentTypes = await paymentTypesApi.getAll({ signal });
      return map(paymentTypes, (paymentType) => ({
        ...paymentType,
        name: tCommon(`features.requisites.types.${paymentType.code}`, {
          defaultValue: paymentType.name,
        }),
      }));
    },
  );
  const queryResultBanks = useQuery(QueryKey.Banks, banksApi.getAll);
  const queryResultFiatCurrencies = useQuery(
    QueryKey.FiatCurrencies,
    fiatCurrencyApi.getAll,
  );

  const tradeMethodDetailsDialogData = useMemo(
    () => ({
      banks: queryResultBanks.data || [],
      paymentTypes: queryResultPaymentTypes.data || [],
      fiatCurrencies: queryResultFiatCurrencies.data || [],
    }),
    [
      queryResultBanks.data,
      queryResultFiatCurrencies.data,
      queryResultPaymentTypes.data,
    ],
  );

  const [createTradeMethodDialogOpen, setCreateTradeMethodDialogOpen] =
    useState(false);

  const paths = useMemo(() => {
    if (isAdmin) {
      return {
        automationSources: ROUTE_PATH.ADMIN.TRADE_METHOD_AUTOMATION_SOURCES,
        parsing: ROUTE_PATH.ADMIN.TRADE_METHOD_PARSINGS,
        filesValidation: ROUTE_PATH.ADMIN.TRADE_METHOD_FILES_VALIDATION,
      };
    } else if (isTechOperator) {
      return {
        automationSources:
          ROUTE_PATH.TECH_OPERATOR.TRADE_METHOD_AUTOMATION_SOURCES,
        parsing: ROUTE_PATH.TECH_OPERATOR.TRADE_METHOD_PARSINGS,
        filesValidation: ROUTE_PATH.TECH_OPERATOR.TRADE_METHOD_FILES_VALIDATION,
      };
    }
  }, [isAdmin, isTechOperator]);

  const columns: DataGridColumnDefinition<TradeMethod>[] = useMemo(
    () => [
      {
        header: t('fields.id'),
        valueGetter: (item) => <CopyTextId id={item.id} />,
      },
      {
        header: t('fields.fiat_currency'),
        valueKey: 'fiatCurrencyId',
        valueFormatter: (fiatCurrencyId) =>
          find(queryResultFiatCurrencies.data, { id: fiatCurrencyId })?.code,
      },
      {
        header: t('fields.payment_type'),
        valueKey: 'paymentTypeId',
        valueFormatter: (paymentTypeId) =>
          find(queryResultPaymentTypes.data, { id: paymentTypeId })?.name,
      },
      {
        header: t('fields.bank'),
        valueKey: 'bankId',
        valueFormatter: (bankId) =>
          find(queryResultBanks.data, { id: bankId })?.name,
      },
    ],
    [
      t,
      queryResultBanks.data,
      queryResultFiatCurrencies.data,
      queryResultPaymentTypes.data,
    ],
  );
  const queryResult = usePartialQuery(
    QueryKey.TradeMethods,
    tradeMethodsApi.getAllPaginated,
  );

  const { mutate: remove } = useMutation(tradeMethodsApi.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.TradeMethods);
    },
    notifierType: 'remove',
  });

  const { mutate: update } = useMutation<
    TradeMethod,
    AxiosError,
    { id: string; data: Partial<TradeMethod> }
  >(tradeMethodsApi.update);
  const { mutate: create } = useMutation<
    TradeMethod,
    AxiosError,
    TradeMethodDto
  >(tradeMethodsApi.create);

  const handleUpdate = useCallback(
    (
      item: TradeMethod,
      data: CloseFormikDialogData<Partial<TradeMethod>>,
      closeDialog: () => void,
    ) => {
      update(
        { id: item.id, data: data?.values },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.TradeMethods);
            closeDialog();
          },
          onError: (error: AxiosError) => {
            data?.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        },
      );
    },
    [queryClient, update],
  );

  const handleCreateClick = useCallback(() => {
    setCreateTradeMethodDialogOpen(true);
  }, []);

  const handleCreateTradeMethodDialogClose = useCallback(
    ({ data, ok }: CloseFormikDialogResult<TradeMethodDto>) => {
      if (ok && data) {
        return create(data.values, {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.TradeMethods);
            data.formikHelpers.resetForm();
            setCreateTradeMethodDialogOpen(false);
          },
          onError: (error: AxiosError) => {
            data.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        });
      }
      setCreateTradeMethodDialogOpen(false);
    },
    [queryClient, create],
  );

  const renderCustomAction = useCallback(
    (props: { title: string; url: string; Icon: SvgIconComponent }) => ({
      type: CrudTableActionType.Custom,
      render: (item: TradeMethod) => (
        <Link to={generatePath(props.url, { id: item.id })}>
          <IconButton title={props.title} color="primary">
            <props.Icon />
          </IconButton>
        </Link>
      ),
    }),
    [],
  );

  const filtersDefinitions: FilterDefinition<TradeMethodsFilters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      {
        label: tCommon('filters.payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (value: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: value })!,
          ),
      },
      {
        label: tCommon('filters.bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (value: string) => find(banks, { id: value })?.name,
      },
    ],
    [banks, fiatCurrenciesOptions, getFiatCurrencyCode, paymentTypes, tCommon],
  );

  return (
    <Fragment>
      <CrudPage
        header={{
          title: t('title'),
          rightContentButton: { onClick: handleCreateClick },
        }}
        filters={{ filtersDefinitions }}
        table={{
          paginated: true,
          queryResult,
          columns,
          actions: [
            renderCustomAction({
              title: t('buttons.automation_sources'),
              url: paths!.automationSources,
              Icon: AbcIcon,
            }),
            renderCustomAction({
              title: t('buttons.parsing'),
              url: paths!.parsing,
              Icon: CodeIcon,
            }),
            renderCustomAction({
              title: t('buttons.files_validation'),
              url: paths!.filesValidation,
              Icon: ReceiptOutlinedIcon,
            }),
            {
              type: CrudTableActionType.Details,
              renderDialog: (props) => (
                <TradeMethodDetailsDialog
                  {...tradeMethodDetailsDialogData}
                  {...props}
                />
              ),
              onUpdate: handleUpdate,
            },
            {
              type: CrudTableActionType.Remove,
              onRemove: (item, { close }) =>
                remove(item.id, { onSuccess: close }),
            },
          ],
        }}
      ></CrudPage>
      <TradeMethodDetailsDialog
        {...tradeMethodDetailsDialogData}
        open={createTradeMethodDialogOpen}
        onClose={handleCreateTradeMethodDialogClose}
      />
    </Fragment>
  );
};
