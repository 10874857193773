import { FormControl, Typography } from '@mui/material';
import { FieldArray } from 'formik';
import { filter } from 'lodash';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormikCheckbox,
  FormikMultiSelect,
  FormikNumericField,
  FormikSelect,
  FormikTextField,
} from 'components';
import { OrderType, P2PProviderType } from 'enums';
import { useCurrencies, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { TradeMethod } from 'types';
import { tradeMethodsUtils } from 'utils';

export type ConfigName = 'payinConfig' | 'payoutConfig';

type Props = {
  configType: OrderType;
  providerType?: P2PProviderType;
  fiatCurrencyId?: string;
  assetCurrencyId?: string;
  tradeMethods?: { id: string }[];
  onTradeMethodsChange: (event: any, configName: ConfigName) => void;
};

export const P2PProviderConfig: React.FC<Props> = ({
  configType,
  providerType,
  fiatCurrencyId,
  assetCurrencyId,
  tradeMethods,
  onTradeMethodsChange,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });

  const { defaultAssetCurrency, getAssetCurrencyExchangeOptions } =
    useCurrencies();

  const {
    tradeMethods: allTradeMethods,
    banks,
    paymentTypes,
    fiatCurrencies,
  } = useUserContext();

  const title = useMemo(
    () =>
      configType === OrderType.Payin
        ? tCommon('common.payin')
        : tCommon('common.payout'),
    [configType, tCommon],
  );

  const configName: ConfigName = useMemo(
    () => (configType === OrderType.Payin ? 'payinConfig' : 'payoutConfig'),
    [configType],
  );

  const filteredTradeMethods: TradeMethod[] = useMemo(
    () =>
      filter(allTradeMethods, {
        fiatCurrencyId,
      }),
    [fiatCurrencyId, allTradeMethods],
  );

  const tradeMethodsOptions = useMemo(
    () =>
      tradeMethodsUtils.getTradeMethodOptions({
        tradeMethods: filteredTradeMethods,
        banks,
        paymentTypes,
        fiatCurrencies,
      }),
    [filteredTradeMethods, banks, paymentTypes, fiatCurrencies],
  );

  const isTradeMethodsSelectDisabled = useMemo(
    () => !fiatCurrencyId || !tradeMethodsOptions.length,
    [fiatCurrencyId, tradeMethodsOptions],
  );

  const renderCredentials = useCallback(
    () => (
      <Fragment>
        <Typography>{t('fields.credentials')}</Typography>
        {providerType === P2PProviderType.InternalLike && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_key')}
              name={`${configName}.credentials.apiKey`}
            />
            <FormikTextField
              label={t('fields.signature_key')}
              name={`${configName}.credentials.signatureKey`}
            />
          </Fragment>
        )}
        {providerType === P2PProviderType.Trust2Pay && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_secret')}
              name={`${configName}.credentials.apiSecret`}
            />
            <FormikTextField
              label={t('fields.password')}
              name={`${configName}.credentials.password`}
            />
          </Fragment>
        )}
        {providerType === P2PProviderType.X2X && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_secret')}
              name={`${configName}.credentials.apiSecret`}
            />
            <FormikTextField
              label={t('fields.password')}
              name={`${configName}.credentials.password`}
            />
          </Fragment>
        )}
        {providerType === P2PProviderType.Payscrow && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_key')}
              name={`${configName}.credentials.apiKey`}
            />
            <FormikTextField
              label={t('fields.api_secret')}
              name={`${configName}.credentials.apiSecret`}
            />
          </Fragment>
        )}
        {providerType === P2PProviderType.Namba && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_token')}
              name={`${configName}.credentials.apiToken`}
            />
          </Fragment>
        )}
        {providerType === P2PProviderType.Paycraft && (
          <Fragment>
            <FormikTextField
              label={t('fields.api_private')}
              name={`${configName}.credentials.apiPrivate`}
            />
            <FormikTextField
              label={t('fields.api_public')}
              name={`${configName}.credentials.apiPublic`}
            />
          </Fragment>
        )}
      </Fragment>
    ),
    [providerType, configName, t],
  );

  return (
    <Fragment>
      <Typography variant="h5">{title}</Typography>
      <FormControl sx={{ alignSelf: 'flex-start' }}>
        <FormikCheckbox
          label={t('fields.enabled')}
          name={`${configName}.enabled`}
        />
      </FormControl>
      <FormControl sx={{ alignSelf: 'flex-start' }}>
        <FormikCheckbox
          label={t('fields.polling_enabled')}
          name={`${configName}.pollingEnabled`}
        />
      </FormControl>
      {assetCurrencyId && assetCurrencyId !== defaultAssetCurrency?.id && (
        <FormikSelect
          label={t('fields.withdrawal_currency_exchange')}
          name={`${configName}.assetCurrencyExchangeId`}
          required
          helperText={t('fields.withdrawal_currency_exchange_description')}
          options={getAssetCurrencyExchangeOptions(assetCurrencyId)}
          noneOption
        />
      )}
      <FormikTextField
        label={t('fields.url')}
        name={`${configName}.url`}
        required
      />

      {renderCredentials()}

      <FormikNumericField
        label={t('fields.response_timeout')}
        name={`${configName}.responseTimeout`}
        suffix={tCommon('suffixes.ms')}
        allowNegative={false}
        fullWidth
        helperText={t('fields.response_timeout_description')}
      />
      <FormikNumericField
        label={t('fields.expected_fee')}
        name={`${configName}.expectedFee`}
        allowNegative={false}
        percentageSuffix
        fullWidth
        required
      />

      <FieldArray
        name={`${configName}.tradeMethods`}
        render={() => {
          const multiSelectValue = tradeMethods?.map(({ id }) => id) || [];

          return (
            <FormikMultiSelect
              label={t('fields.trade_methods')}
              name={`${configName}.tradeMethods`}
              options={tradeMethodsOptions}
              value={multiSelectValue}
              onChange={(e) => onTradeMethodsChange(e, configName)}
              disabled={isTradeMethodsSelectDisabled}
            />
          );
        }}
      />
    </Fragment>
  );
};
