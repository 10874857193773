import { Stack, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyText, DescriptionRow, StylizedNumber } from 'components';
import { OrderType } from 'enums';
import { useCurrencies, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProviderPayinConfig, P2PProviderPayoutConfig } from 'types';
import { formatUtils, tradeMethodsUtils } from 'utils';

type Props = {
  configType: OrderType;
  config: P2PProviderPayinConfig | P2PProviderPayoutConfig;
};

export const P2PProviderConfigDetails: React.FC<Props> = ({
  configType,
  config,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const { banks, tradeMethods, paymentTypes } = useUserContext();
  const { fiatCurrencies } = useCurrencies();

  const title = useMemo(() => {
    if (configType === OrderType.Payin) {
      return t('details.payin_details');
    } else {
      return t('details.payout_details');
    }
  }, [configType, t]);

  return (
    <Fragment>
      <Typography variant="h6">{title}</Typography>
      <DescriptionRow
        title={t('details.status.title')}
        value={
          config?.enabled
            ? t('details.status.enabled')
            : t('details.status.disabled')
        }
      />
      <DescriptionRow title={t('details.url')} value={config?.url} canCopy />
      <DescriptionRow
        title={t('details.api_key')}
        value={config?.credentials?.apiKey}
        canCopy
      />
      <DescriptionRow
        title={t('details.signature_key')}
        value={config?.credentials?.signatureKey}
        canCopy
      />
      <DescriptionRow
        title={t('details.response_timeout')}
        description={t('details.response_timeout_description')}
        value={
          config?.responseTimeout
            ? `${formatUtils.formatNumber(config?.responseTimeout)}${tCommon(
                'suffixes.ms',
              )}`
            : ''
        }
      />
      <DescriptionRow
        title={t('details.expected_fee')}
        value={
          isNumber(config?.expectedFee) && (
            <StylizedNumber value={config.expectedFee} unit="%" />
          )
        }
      />

      {config?.lastTakenOrderAt && (
        <DescriptionRow
          title={t('details.last_taken_order_at')}
          value={formatUtils.formatDate(config.lastTakenOrderAt)}
        />
      )}

      <DescriptionRow
        title={t('details.trade_methods')}
        value={
          <Stack direction="column" spacing={2}>
            {config?.tradeMethods?.map(({ id }) => (
              <Stack key={id} direction="column" spacing={0}>
                <CopyText text={id} />
                <div>
                  {tradeMethodsUtils.getTradeMethodLabel({
                    tradeMethodId: id,
                    banks,
                    tradeMethods,
                    paymentTypes,
                    fiatCurrencies,
                  })}
                </div>
              </Stack>
            ))}
          </Stack>
        }
      />
    </Fragment>
  );
};
