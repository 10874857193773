import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import {
  AdminRoute,
  MerchantRoute,
  OperatorRoute,
  PayRoutes,
  PublicRoute,
  TechOperatorRoute,
  TraderRoute,
} from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { BalanceReconciliationPage } from 'features/balance-reconciliation';
import { BlockedCustomers } from 'features/blocked-customers';
import { CurrencyExchangeRequestsPage } from 'features/currency-exchange-requests';
import { ExecutionMetricsPage } from 'features/execution-metrics';
import {
  P2PProvidersPage,
  P2PProviderDetailsPage,
} from 'features/p2p-providers';
import { RequisitesGroupDetails } from 'features/requisites';
import {
  AdminArchivePage,
  AdminAuditPage,
  AcceptInvitePage,
  AdminAnomaliesPage,
  AdminAssetBalanceHistoryPage,
  AdminCurrenciesPage,
  AdminCurrencyExchangeDetailsPage,
  AdminBanksPage,
  AdminFundsRequestsPage,
  AdminGroupDetailsPage,
  AdminGroupsPage,
  AdminMainPage,
  AdminOrdersPage,
  AdminPayinOrdersPage,
  AdminPayoutOrdersPage,
  AdminOrderAutomationPage,
  AdminRequisitesPage,
  AdminPayinRequisitesPage,
  AdminPayoutRequisitesPage,
  AdminPayinRequisitesDetailsPage,
  AdminRequisitesDistributionPage,
  AdminSettingsPage,
  AdminPayinStatisticsPage,
  AdminPayoutStatisticsPage,
  AdminShopsPage,
  AdminShopDetailsPage,
  AdminTraderDetails,
  AdminTradeMethodsPage,
  AdminTradersPage,
  AdminUserDetailsPage,
  AdminUsersPage,
  AdminPayinCashbackPage,
  AdminPayoutCashbackPage,
  AdminWebhooksPage,
  AdminWalletsPage,
  AdminInternalTransfersPage,
  AdminAutomationSourcesPage,
  AdminTransactionsPage,
  AdminDepositTransactionsPage,
  AdminWithdrawalTransactionsPage,
  AdminTradeMethodFilesValidationPage,
  AdminSciDomainsPage,
  AdminTradeMethodAutomationSourcesPage,
  AdminTradeMethodParsingsPage,
  LoginPage,
  MerchantSettingsPage,
  MerchantShopsPage,
  OperatorMainPage,
  OperatorOrdersPage,
  OperatorPayinOrdersPage,
  OperatorPayoutOrdersPage,
  OperatorPayinRequisitesPage,
  OperatorPayoutRequisitesPage,
  OperatorRequisitesPage,
  OperatorSettingsPage,
  OperatorTradersPage,
  SessionExpiredPage,
  AccountDisabledPage,
  TraderBalanceHistoryPage,
  TraderFundsRequestsPage,
  TraderOrderAutomationPage,
  TraderOrdersPage,
  TraderPayinOrdersPage,
  TraderPayoutOrdersPage,
  TraderMainPage,
  TraderPayinStatisticsPage,
  TraderPayoutStatisticsPage,
  TraderReferralSystemPage,
  TraderRequisitesPage,
  TraderPayinRequisitesPage,
  TraderPayinRequisitesDetailsPage,
  TraderSettingsPage,
  TraderPayoutRequisitesPage,
  TraderPayoutRequisitesDetailsPage,
  TraderInternalTransfersPage,
  TraderRequisitesTelegramAutomationDetailsPage,
  MerchantBalanceHistoryPage,
  MerchantFundsRequestsPage,
  MerchantMainPage,
  MerchantShopDetailsPage,
  MerchantPayinStatisticsPage,
  MerchantPayoutStatisticsPage,
  MerchantPayinOrdersPage,
  MerchantPayoutOrdersPage,
  MerchantWebhooksPage,
  MerchantOrdersPage,
  MerchantInternalTransfersPage,
  OperatorArchivePage,
  OperatorAssetsPage,
  OperatorOrderAutomationPage,
  TechOperatorFundsRequestsPage,
  TechOperatorMainPage,
  TechOperatorPayinOrdersPage,
  TechOperatorPayoutOrdersPage,
  TechOperatorOrdersPage,
  TechOperatorTraderDetails,
  TechOperatorTradersPage,
  TechOperatorShopsPage,
  TechOperatorShopDetailsPage,
  TechOperatorPayinCashbackPage,
  TechOperatorPayoutCashbackPage,
  TechOperatorGroupsPage,
  TechOperatorGroupDetailsPage,
  TechOperatorUsersPage,
  TechOperatorUserDetailsPage,
  TechOperatorSettingsPage,
  TechOperatorCurrenciesPage,
  TechOperatorCurrencyExchangeDetailsPage,
  TechOperatorBanksPage,
  TechOperatorTradeMethodsPage,
  TechOperatorTradeMethodAutomationSourcesPage,
  TechOperatorTradeMethodParsingsPage,
  TechOperatorTradeMethodFilesValidationPage,
  TechOperatorTransactionsPage,
  TechOperatorDepositTransactionsPage,
  TechOperatorWithdrawalTransactionsPage,
  TechOperatorOrderAutomationPage,
  TechOperatorAutomationSourcesPage,
  TechOperatorWebhooksPage,
  TechOperatorSciDomainsPage,
  TechOperatorRequisitesPage,
  TechOperatorPayinRequisitesPage,
  TechOperatorPayoutRequisitesPage,
  TechOperatorArchivePage,
  TechOperatorAssetBalanceHistoryPage,
  TechOperatorAnomaliesPage,
  TechOperatorPayinStatisticsPage,
  TechOperatorPayoutStatisticsPage,
  TechOperatorAuditPage,
  TechOperatorRequisitesDistributionPage,
  TechOperatorPayinRequisitesDetailsPage,
} from 'pages';

// TODO: import routes from role-related file
export const AppRoutes: React.FC = () => (
  <Routes>
    <Route path={ROUTE_PATH.ADMIN.MAIN} element={<AdminRoute />}>
      <Route path="" index element={<AdminMainPage />} />
      <Route path={ROUTE_PATH.ADMIN.SETTINGS} element={<AdminSettingsPage />} />
      <Route path={ROUTE_PATH.ADMIN.USERS} element={<AdminUsersPage />} />
      <Route path={ROUTE_PATH.ADMIN.TRADERS} element={<AdminTradersPage />} />
      <Route path={ROUTE_PATH.ADMIN.SHOPS} element={<AdminShopsPage />} />

      <Route
        path={ROUTE_PATH.ADMIN.REQUISITES}
        element={<Navigate to={ROUTE_PATH.ADMIN.PAYIN_REQUISITES} />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.SHOP_DETAILS}
        element={<AdminShopDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.REQUISITES}
        element={<AdminRequisitesPage />}
      >
        <Route
          index
          path={ROUTE_PATH.ADMIN.PAYIN_REQUISITES}
          element={<AdminPayinRequisitesPage />}
        />
        <Route
          path={ROUTE_PATH.ADMIN.PAYOUT_REQUISITES}
          element={<AdminPayoutRequisitesPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.ADMIN.PAYIN_REQUISITES} />}
        />
      </Route>
      <Route
        path={ROUTE_PATH.ADMIN.PAYIN_REQUISITES_DETAILS}
        element={<AdminPayinRequisitesDetailsPage />}
      />
      <Route
        path={`${ROUTE_PATH.ADMIN.CASHBACK}/*`}
        element={
          <PayRoutes
            path={ROUTE_PATH.ADMIN.CASHBACK}
            payinElement={<AdminPayinCashbackPage />}
            payoutElement={<AdminPayoutCashbackPage />}
          />
        }
      ></Route>
      <Route
        path={ROUTE_PATH.ADMIN.PAYIN_REQUISITES_GROUP_DETAILS}
        element={<RequisitesGroupDetails />}
      />

      <Route
        path={ROUTE_PATH.ADMIN.TRADER_DETAILS}
        element={<AdminTraderDetails />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.USER_DETAILS}
        element={<AdminUserDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.FUNDS_REQUESTS}
        element={<AdminFundsRequestsPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.INTERNAL_TRANSFERS}
        element={<AdminInternalTransfersPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.ASSET_BALANCE_HISTORY}
        element={<AdminAssetBalanceHistoryPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.PAYIN_ORDERS}
        element={<AdminOrdersPage />}
      />

      <Route
        path={ROUTE_PATH.ADMIN.ORDERS}
        element={<Navigate to={ROUTE_PATH.ADMIN.PAYIN_ORDERS} />}
      />
      <Route path={ROUTE_PATH.ADMIN.ORDERS} element={<AdminOrdersPage />}>
        <Route
          index
          path={ROUTE_PATH.ADMIN.PAYIN_ORDERS}
          element={<AdminPayinOrdersPage />}
        />
        <Route
          path={ROUTE_PATH.ADMIN.PAYOUT_ORDERS}
          element={<AdminPayoutOrdersPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.ADMIN.PAYIN_ORDERS} />}
        />
      </Route>

      <Route path={ROUTE_PATH.ADMIN.GROUPS} element={<AdminGroupsPage />} />
      <Route
        path={ROUTE_PATH.ADMIN.GROUP_DETAILS}
        element={<AdminGroupDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.CURRENCIES}
        element={<AdminCurrenciesPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.CURRENCY_EXCHANGE_DETAILS}
        element={<AdminCurrencyExchangeDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.DISTRIBUTION}
        element={<AdminRequisitesDistributionPage />}
      />
      <Route path={ROUTE_PATH.ADMIN.BANKS} element={<AdminBanksPage />} />

      <Route
        path={`${ROUTE_PATH.ADMIN.STATISTICS}/*`}
        element={
          <PayRoutes
            path={ROUTE_PATH.ADMIN.STATISTICS}
            payinElement={<AdminPayinStatisticsPage />}
            payoutElement={<AdminPayoutStatisticsPage />}
          />
        }
      />
      <Route
        path={ROUTE_PATH.ADMIN.TRANSACTIONS}
        element={<Navigate to={ROUTE_PATH.ADMIN.DEPOSIT_TRANSACTIONS} />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.TRANSACTIONS}
        element={<AdminTransactionsPage />}
      >
        <Route
          index
          path={ROUTE_PATH.ADMIN.DEPOSIT_TRANSACTIONS}
          element={<AdminDepositTransactionsPage />}
        />
        <Route
          path={ROUTE_PATH.ADMIN.WITHDRAWAL_TRANSACTIONS}
          element={<AdminWithdrawalTransactionsPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.ADMIN.DEPOSIT_TRANSACTIONS} />}
        />
      </Route>

      <Route path={ROUTE_PATH.ADMIN.TRADE_METHODS}>
        <Route
          index
          path={ROUTE_PATH.ADMIN.TRADE_METHODS}
          element={<AdminTradeMethodsPage />}
        />
        <Route
          path={ROUTE_PATH.ADMIN.TRADE_METHOD_PARSINGS}
          element={<AdminTradeMethodParsingsPage />}
        />
        <Route
          path={ROUTE_PATH.ADMIN.TRADE_METHOD_AUTOMATION_SOURCES}
          element={<AdminTradeMethodAutomationSourcesPage />}
        />
        <Route
          path={ROUTE_PATH.ADMIN.TRADE_METHOD_FILES_VALIDATION}
          element={<AdminTradeMethodFilesValidationPage />}
        />
      </Route>
      <Route
        path={ROUTE_PATH.ADMIN.ANOMALIES}
        element={<AdminAnomaliesPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.ORDER_AUTOMATION}
        element={<AdminOrderAutomationPage />}
      />
      <Route path={ROUTE_PATH.ADMIN.ARCHIVE} element={<AdminArchivePage />} />
      <Route path={ROUTE_PATH.ADMIN.WEBHOOKS} element={<AdminWebhooksPage />} />
      <Route
        path={ROUTE_PATH.ADMIN.AUTOMATION_SOURCES}
        element={<AdminAutomationSourcesPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.SCI_DOMAINS}
        element={<AdminSciDomainsPage />}
      />
      <Route path={ROUTE_PATH.ADMIN.WALLETS} element={<AdminWalletsPage />} />
      <Route path={ROUTE_PATH.ADMIN.AUDIT} element={<AdminAuditPage />} />
      <Route
        path={ROUTE_PATH.ADMIN.BALANCE_RECONCILIATION}
        element={<BalanceReconciliationPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.P2P_PROVIDERS}
        element={<P2PProvidersPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.P2P_PROVIDERS_DETAILS}
        element={<P2PProviderDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.CURRENCY_EXCHANGE}
        element={<CurrencyExchangeRequestsPage />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.BLOCKED_CUSTOMERS}
        element={<BlockedCustomers />}
      />
      <Route
        path={ROUTE_PATH.ADMIN.EXECUTION_METRICS}
        element={<ExecutionMetricsPage />}
      />
    </Route>
    <Route path={ROUTE_PATH.OPERATOR.MAIN} element={<OperatorRoute />}>
      <Route
        path={ROUTE_PATH.OPERATOR.SETTINGS}
        element={<OperatorSettingsPage />}
      />
      <Route
        path={ROUTE_PATH.OPERATOR.TRADERS}
        element={<OperatorTradersPage />}
      />
      <Route
        path={ROUTE_PATH.OPERATOR.ORDER_AUTOMATION}
        element={<OperatorOrderAutomationPage />}
      />
      <Route
        path={ROUTE_PATH.OPERATOR.ASSETS}
        element={<OperatorAssetsPage />}
      />
      <Route
        path={ROUTE_PATH.OPERATOR.ARCHIVE}
        element={<OperatorArchivePage />}
      />
      <Route
        path={ROUTE_PATH.OPERATOR.REQUISITES}
        element={<OperatorRequisitesPage />}
      >
        <Route
          index
          path={ROUTE_PATH.OPERATOR.PAYIN_REQUISITES}
          element={<OperatorPayinRequisitesPage />}
        />
        <Route
          path={ROUTE_PATH.OPERATOR.PAYOUT_REQUISITES}
          element={<OperatorPayoutRequisitesPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.OPERATOR.PAYIN_REQUISITES} />}
        />
      </Route>
      <Route
        path={ROUTE_PATH.OPERATOR.ORDERS}
        element={<Navigate to={ROUTE_PATH.OPERATOR.PAYIN_ORDERS} />}
      />
      <Route path={ROUTE_PATH.OPERATOR.ORDERS} element={<OperatorOrdersPage />}>
        <Route
          index
          path={ROUTE_PATH.OPERATOR.PAYIN_ORDERS}
          element={<OperatorPayinOrdersPage />}
        />
        <Route
          path={ROUTE_PATH.OPERATOR.PAYOUT_ORDERS}
          element={<OperatorPayoutOrdersPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.OPERATOR.PAYIN_ORDERS} />}
        />
      </Route>
      <Route path="" element={<OperatorMainPage />} />
    </Route>
    <Route path={ROUTE_PATH.TECH_OPERATOR.MAIN} element={<TechOperatorRoute />}>
      <Route path="" index element={<TechOperatorMainPage />} />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.FUNDS_REQUESTS}
        element={<TechOperatorFundsRequestsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.INTERNAL_TRANSFERS}
        element={<AdminInternalTransfersPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.ORDERS}
        element={<TechOperatorOrdersPage />}
      >
        <Route
          index
          path={ROUTE_PATH.TECH_OPERATOR.PAYIN_ORDERS}
          element={<TechOperatorPayinOrdersPage />}
        />
        <Route
          path={ROUTE_PATH.TECH_OPERATOR.PAYOUT_ORDERS}
          element={<TechOperatorPayoutOrdersPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.TECH_OPERATOR.PAYIN_ORDERS} />}
        />
      </Route>
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.TRADERS}
        element={<TechOperatorTradersPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.TRADER_DETAILS}
        element={<TechOperatorTraderDetails />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.SHOPS}
        element={<TechOperatorShopsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.SHOP_DETAILS}
        element={<TechOperatorShopDetailsPage />}
      />
      <Route
        path={`${ROUTE_PATH.TECH_OPERATOR.CASHBACK}/*`}
        element={
          <PayRoutes
            path={ROUTE_PATH.TECH_OPERATOR.CASHBACK}
            payinElement={<TechOperatorPayinCashbackPage />}
            payoutElement={<TechOperatorPayoutCashbackPage />}
          />
        }
      ></Route>
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.GROUPS}
        element={<TechOperatorGroupsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.GROUP_DETAILS}
        element={<TechOperatorGroupDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.USERS}
        element={<TechOperatorUsersPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.USER_DETAILS}
        element={<TechOperatorUserDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.SETTINGS}
        element={<TechOperatorSettingsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.CURRENCIES}
        element={<TechOperatorCurrenciesPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.CURRENCY_EXCHANGE_DETAILS}
        element={<TechOperatorCurrencyExchangeDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.BANKS}
        element={<TechOperatorBanksPage />}
      />
      <Route path={ROUTE_PATH.TECH_OPERATOR.TRADE_METHODS}>
        <Route
          index
          path={ROUTE_PATH.TECH_OPERATOR.TRADE_METHODS}
          element={<TechOperatorTradeMethodsPage />}
        />
        <Route
          path={ROUTE_PATH.TECH_OPERATOR.TRADE_METHOD_PARSINGS}
          element={<TechOperatorTradeMethodParsingsPage />}
        />
        <Route
          path={ROUTE_PATH.TECH_OPERATOR.TRADE_METHOD_AUTOMATION_SOURCES}
          element={<TechOperatorTradeMethodAutomationSourcesPage />}
        />
        <Route
          path={ROUTE_PATH.TECH_OPERATOR.TRADE_METHOD_FILES_VALIDATION}
          element={<TechOperatorTradeMethodFilesValidationPage />}
        />
      </Route>
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.TRANSACTIONS}
        element={
          <Navigate to={ROUTE_PATH.TECH_OPERATOR.DEPOSIT_TRANSACTIONS} />
        }
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.TRANSACTIONS}
        element={<TechOperatorTransactionsPage />}
      >
        <Route
          index
          path={ROUTE_PATH.TECH_OPERATOR.DEPOSIT_TRANSACTIONS}
          element={<TechOperatorDepositTransactionsPage />}
        />
        <Route
          path={ROUTE_PATH.TECH_OPERATOR.WITHDRAWAL_TRANSACTIONS}
          element={<TechOperatorWithdrawalTransactionsPage />}
        />
        <Route
          path="*"
          element={
            <Navigate to={ROUTE_PATH.TECH_OPERATOR.DEPOSIT_TRANSACTIONS} />
          }
        />
      </Route>
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.ORDER_AUTOMATION}
        element={<TechOperatorOrderAutomationPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.AUTOMATION_SOURCES}
        element={<TechOperatorAutomationSourcesPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.WEBHOOKS}
        element={<TechOperatorWebhooksPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.SCI_DOMAINS}
        element={<TechOperatorSciDomainsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.REQUISITES}
        element={<Navigate to={ROUTE_PATH.TECH_OPERATOR.PAYIN_REQUISITES} />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.REQUISITES}
        element={<TechOperatorRequisitesPage />}
      >
        <Route
          index
          path={ROUTE_PATH.TECH_OPERATOR.PAYIN_REQUISITES}
          element={<TechOperatorPayinRequisitesPage />}
        />
        <Route
          path={ROUTE_PATH.TECH_OPERATOR.PAYOUT_REQUISITES}
          element={<TechOperatorPayoutRequisitesPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.TECH_OPERATOR.PAYIN_REQUISITES} />}
        />
      </Route>
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.PAYIN_REQUISITES_DETAILS}
        element={<TechOperatorPayinRequisitesDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.PAYIN_REQUISITES_GROUP_DETAILS}
        element={<RequisitesGroupDetails />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.ARCHIVE}
        element={<TechOperatorArchivePage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.ASSET_BALANCE_HISTORY}
        element={<TechOperatorAssetBalanceHistoryPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.ANOMALIES}
        element={<TechOperatorAnomaliesPage />}
      />
      <Route
        path={`${ROUTE_PATH.TECH_OPERATOR.STATISTICS}/*`}
        element={
          <PayRoutes
            path={ROUTE_PATH.TECH_OPERATOR.STATISTICS}
            payinElement={<TechOperatorPayinStatisticsPage />}
            payoutElement={<TechOperatorPayoutStatisticsPage />}
          />
        }
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.AUDIT}
        element={<TechOperatorAuditPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.DISTRIBUTION}
        element={<TechOperatorRequisitesDistributionPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.P2P_PROVIDERS}
        element={<P2PProvidersPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.P2P_PROVIDERS_DETAILS}
        element={<P2PProviderDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.CURRENCY_EXCHANGE}
        element={<CurrencyExchangeRequestsPage />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.BLOCKED_CUSTOMERS}
        element={<BlockedCustomers />}
      />
      <Route
        path={ROUTE_PATH.TECH_OPERATOR.EXECUTION_METRICS}
        element={<ExecutionMetricsPage />}
      />
    </Route>
    <Route path={ROUTE_PATH.TRADER.MAIN} element={<TraderRoute />}>
      <Route path="" element={<TraderMainPage />} />
      <Route
        path={ROUTE_PATH.TRADER.FUNDS_REQUESTS}
        element={<TraderFundsRequestsPage />}
      />
      <Route
        path={ROUTE_PATH.TRADER.INTERNAL_TRANSFERS}
        element={<TraderInternalTransfersPage />}
      />
      <Route
        path={ROUTE_PATH.TRADER.BALANCE_HISTORY}
        element={<TraderBalanceHistoryPage />}
      />
      <Route
        path={ROUTE_PATH.TRADER.SETTINGS}
        element={<TraderSettingsPage />}
      />

      <Route
        path={ROUTE_PATH.TRADER.REQUISITES}
        element={<Navigate to={ROUTE_PATH.TRADER.PAYIN_REQUISITES} />}
      />
      <Route
        path={ROUTE_PATH.TRADER.REQUISITES}
        element={<TraderRequisitesPage />}
      >
        <Route
          index
          path={ROUTE_PATH.TRADER.PAYIN_REQUISITES}
          element={<TraderPayinRequisitesPage />}
        />
        <Route
          path={ROUTE_PATH.TRADER.PAYOUT_REQUISITES}
          element={<TraderPayoutRequisitesPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.TRADER.PAYIN_REQUISITES} />}
        />
      </Route>

      <Route
        path={ROUTE_PATH.TRADER.PAYIN_REQUISITES_DETAILS}
        element={<TraderPayinRequisitesDetailsPage />}
      />
      <Route
        path={ROUTE_PATH.TRADER.PAYIN_REQUISITES_GROUP_DETAILS}
        element={<RequisitesGroupDetails />}
      />
      <Route
        path={ROUTE_PATH.TRADER.PAYOUT_REQUISITES_DETAILS}
        element={<TraderPayoutRequisitesDetailsPage />}
      />

      <Route
        path={ROUTE_PATH.TRADER.ORDERS}
        element={<Navigate to={ROUTE_PATH.TRADER.PAYIN_ORDERS} />}
      />
      <Route path={ROUTE_PATH.TRADER.ORDERS} element={<TraderOrdersPage />}>
        <Route
          index
          path={ROUTE_PATH.TRADER.PAYIN_ORDERS}
          element={<TraderPayinOrdersPage />}
        />
        <Route
          path={ROUTE_PATH.TRADER.PAYOUT_ORDERS}
          element={<TraderPayoutOrdersPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.TRADER.PAYIN_ORDERS} />}
        />
      </Route>

      <Route
        path={ROUTE_PATH.TRADER.REFERRAL_SYSTEM}
        element={<TraderReferralSystemPage />}
      />
      <Route
        path={ROUTE_PATH.TRADER.ORDER_AUTOMATION}
        element={<TraderOrderAutomationPage />}
      />
      <Route
        path={ROUTE_PATH.TRADER.REQUISITES_TELEGRAM_AUTOMATION_DETAILS}
        element={<TraderRequisitesTelegramAutomationDetailsPage />}
      />

      <Route
        path={`${ROUTE_PATH.TRADER.STATISTICS}/*`}
        element={
          <PayRoutes
            path={ROUTE_PATH.TRADER.STATISTICS}
            payinElement={<TraderPayinStatisticsPage />}
            payoutElement={<TraderPayoutStatisticsPage />}
          />
        }
      />
      <Route
        path={ROUTE_PATH.TRADER.CURRENCY_EXCHANGE}
        element={<CurrencyExchangeRequestsPage />}
      />
    </Route>
    <Route path={ROUTE_PATH.MERCHANT.MAIN} element={<MerchantRoute />}>
      <Route path="" element={<MerchantMainPage />} />
      <Route
        path={ROUTE_PATH.MERCHANT.FUNDS_REQUESTS}
        element={<MerchantFundsRequestsPage />}
      />
      <Route
        path={ROUTE_PATH.MERCHANT.INTERNAL_TRANSFERS}
        element={<MerchantInternalTransfersPage />}
      />
      <Route
        path={ROUTE_PATH.MERCHANT.BALANCE_HISTORY}
        element={<MerchantBalanceHistoryPage />}
      />

      <Route
        path={ROUTE_PATH.MERCHANT.SETTINGS}
        element={<MerchantSettingsPage />}
      />
      <Route path={ROUTE_PATH.MERCHANT.SHOPS} element={<MerchantShopsPage />} />

      <Route
        path={ROUTE_PATH.MERCHANT.SHOP_DETAILS}
        element={<MerchantShopDetailsPage />}
      />

      <Route
        path={ROUTE_PATH.MERCHANT.WEBHOOKS}
        element={<MerchantWebhooksPage />}
      />
      <Route
        path={ROUTE_PATH.MERCHANT.ORDERS}
        element={<Navigate to={ROUTE_PATH.MERCHANT.PAYIN_ORDERS} />}
      />
      <Route path={ROUTE_PATH.MERCHANT.ORDERS} element={<MerchantOrdersPage />}>
        <Route
          index
          path={ROUTE_PATH.MERCHANT.PAYIN_ORDERS}
          element={<MerchantPayinOrdersPage />}
        />
        <Route
          path={ROUTE_PATH.MERCHANT.PAYOUT_ORDERS}
          element={<MerchantPayoutOrdersPage />}
        />
        <Route
          path="*"
          element={<Navigate to={ROUTE_PATH.MERCHANT.PAYIN_ORDERS} />}
        />
      </Route>

      <Route
        path={`${ROUTE_PATH.MERCHANT.STATISTICS}/*`}
        element={
          <PayRoutes
            path={ROUTE_PATH.MERCHANT.STATISTICS}
            payinElement={<MerchantPayinStatisticsPage />}
            payoutElement={<MerchantPayoutStatisticsPage />}
          />
        }
      />
      <Route
        path={ROUTE_PATH.MERCHANT.CURRENCY_EXCHANGE}
        element={<CurrencyExchangeRequestsPage />}
      />
    </Route>
    <Route element={<PublicRoute />}>
      <Route path={ROUTE_PATH.PUBLIC.LOGIN} element={<LoginPage />} />
      <Route
        path={ROUTE_PATH.PUBLIC.ACCEPT_INVITE}
        element={<AcceptInvitePage />}
      />
      <Route
        path={ROUTE_PATH.PUBLIC.SESSION_EXPIRED}
        element={<SessionExpiredPage />}
      />
      <Route
        path={ROUTE_PATH.PUBLIC.ACCOUNT_DISABLED}
        element={<AccountDisabledPage />}
      />
      <Route path="*" element={<Navigate to={ROUTE_PATH.PUBLIC.LOGIN} />} />
    </Route>
    <Route
      path="*"
      element={<Navigate to={ROUTE_PATH.PUBLIC.LOGIN} replace />}
    />
  </Routes>
);
