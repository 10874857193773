import { Link } from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  p2pProvidersPayinWebhooksApi,
  p2pProvidersPayoutWebhooksApi,
} from 'api';
import {
  DataGrid,
  DataGridColumnDefinition,
  dataGridColumns,
  DataWrapper,
  DescriptionRow,
  Dialog,
  Json,
} from 'components';
import { OrderType, QueryKey } from 'enums';
import { usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProviderWebhook } from 'types';

type Props = {
  p2pProviderOrderId: string;
  orderType: OrderType;
};

export const P2PProviderWebhooks: React.FC<Props> = ({
  p2pProviderOrderId,
  orderType,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.p2p_provider_webhooks',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const [dialogOpen, setDialogOpen] = useState(false);
  const prevDialogOpen = usePrevious(dialogOpen);

  const queryResultPayout = useQuery(
    QueryKey.P2PProviderPayoutWebhooks,
    () => p2pProvidersPayoutWebhooksApi.getForOrder(p2pProviderOrderId),
    { enabled: dialogOpen && orderType === OrderType.Payout },
  );

  const queryResultPayin = useQuery(
    QueryKey.P2PProviderPayinWebhooks,
    () => p2pProvidersPayinWebhooksApi.getForOrder(p2pProviderOrderId),
    { enabled: dialogOpen && orderType === OrderType.Payin },
  );

  const queryResult = useMemo(
    () =>
      orderType === OrderType.Payin ? queryResultPayin : queryResultPayout,
    [orderType, queryResultPayin, queryResultPayout],
  );

  const renderData = useCallback(
    (webhook: P2PProviderWebhook) => (
      <Json text={JSON.stringify(webhook.rawData)} />
    ),
    [],
  );

  const columns: DataGridColumnDefinition<P2PProviderWebhook>[] = useMemo(
    () => [
      dataGridColumns.getIdColumn(),
      {
        header: t('dialog.columns.status'),
        valueGetter: (webhook: P2PProviderWebhook) =>
          t(`dialog.labels.status.${webhook.status}`),
      },
      {
        header: t('dialog.columns.raw_data'),
        valueGetter: renderData,
      },
      {
        header: t('dialog.columns.ip'),
        valueKey: 'ip',
      },
      {
        header: t('dialog.columns.error'),
        valueKey: 'error',
      },
    ],
    [t, renderData],
  );

  useEffect(() => {
    if (!prevDialogOpen && dialogOpen) {
      queryResult.refetch();
    }
  }, [prevDialogOpen, dialogOpen, queryResult]);

  return (
    <Fragment>
      <DescriptionRow
        title={t('title')}
        value={
          <div>
            <Link
              component="button"
              variant="body2"
              underline="none"
              onClick={() => setDialogOpen(true)}
            >
              {tCommon('buttons.open')}
            </Link>
          </div>
        }
      />
      <Dialog
        modal
        maxWidth="md"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={t('dialog.title')}
      >
        <DataWrapper queryResult={queryResult}>
          <DataGrid columns={columns} data={queryResult.data}></DataGrid>
        </DataWrapper>
      </Dialog>
    </Fragment>
  );
};
