import axios from 'axios';

import { env } from 'config';
import { P2PProviderRequest } from 'types';

const url = `${env.apiUrl}/p2p-providers-payin-requests`;

export const p2pProvidersPayinRequestsApi = {
  getForOrder: async (orderId: string): Promise<P2PProviderRequest[]> =>
    (await axios.get(`${url}/order/${orderId}`)).data,
};
