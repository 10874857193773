import { Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { keys, map, omit, pick } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { p2pProvidersApi } from 'api';
import {
  EntityDetailsPage,
  EntityDetailsPageChildrenProps,
  FormikNumericField,
  FormikSelect,
  FormikTextField,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { OrderType, P2PProviderType, QueryKey } from 'enums';
import { useCurrencies, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProvider, P2PProviderDto } from 'types';

import { P2P_PROVIDER_INITIAL_VALUE } from './constants';
import { P2PProviderConfig } from './P2PProviderConfig';
import { getP2PProviderValidationSchema } from './utils';

type Values = P2PProviderDto;

export type ConfigName = 'payinConfig' | 'payoutConfig';

export const P2PProviderDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isNew = useMemo(() => id === NEW_ID, [id]);

  const {
    assetCurrenciesOptions,
    fiatCurrenciesOptions,
    defaultAssetCurrency,
    getCurrencyExchangeOptions,
  } = useCurrencies();

  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });

  const { isTechOperator, isAdmin } = useUser();

  const title = useMemo(
    () => (isNew ? t('details.create_title') : t('details.edit_title')),
    [t, isNew],
  );

  const listUrl = useMemo(() => {
    if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.P2P_PROVIDERS;
    } else if (isAdmin) {
      return ROUTE_PATH.ADMIN.P2P_PROVIDERS;
    }
  }, [isAdmin, isTechOperator]);

  const providerTypeOptions = useMemo(
    () =>
      map(P2PProviderType, (type) => ({
        value: type,
        label: t(`types.${type}`),
      })),
    [t],
  );

  const initialValues = useMemo((): Values => P2P_PROVIDER_INITIAL_VALUE, []);

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () => getP2PProviderValidationSchema(defaultAssetCurrency?.id),
    [defaultAssetCurrency?.id],
  );

  const handleTradeMethodsChange = useCallback(
    (event: any, formik: FormikProps<Values>, configName: ConfigName) => {
      const newSelectedTradeMethods = event.target.value;

      formik.setFieldValue(
        `${configName}.tradeMethods`,
        newSelectedTradeMethods.map((id: string) => ({ id })),
      );
    },
    [],
  );

  const resetCurrencyRelatedFields = useCallback(
    (formik: FormikProps<Values>) => {
      formik.setFieldValue('payinConfig.assetCurrencyExchangeId', '');
      formik.setFieldTouched('payinConfig.assetCurrencyExchangeId', true);

      formik.setFieldValue('currencyExchangeId', '');
      formik.setFieldTouched('currencyExchangeId', true);

      formik.setFieldValue('payoutConfig.assetCurrencyExchangeId', '');
      formik.setFieldTouched('payoutConfig.assetCurrencyExchangeId', true);
    },
    [],
  );

  const handleFiatCurrencyChange = useCallback(
    (formik: FormikProps<Values>) => {
      formik.setFieldValue('payinConfig.tradeMethods', []);
      formik.setFieldValue('payoutConfig.tradeMethods', []);
      resetCurrencyRelatedFields(formik);
    },
    [resetCurrencyRelatedFields],
  );

  const handleAssetCurrencyChange = useCallback(
    (formik: FormikProps<Values>) => {
      resetCurrencyRelatedFields(formik);
    },
    [resetCurrencyRelatedFields],
  );

  const initialValuesSelect = useCallback(
    (data: P2PProvider) => {
      const pickedData = pick(data, keys(initialValues));
      pickedData.payinConfig = data.payinConfig
        ? pick(data.payinConfig, keys(initialValues.payinConfig))
        : initialValues.payinConfig;
      pickedData.payoutConfig = data.payoutConfig
        ? pick(data.payoutConfig, keys(initialValues.payoutConfig))
        : initialValues.payoutConfig;
      pickedData.payoutLimits = data.payoutLimits || initialValues.payoutLimits;
      return pickedData;
    },
    [initialValues],
  );

  const renderPayoutLimits = useCallback(
    () => (
      <Fragment>
        <FormikNumericField
          required
          label={t('fields.payout_limit_count')}
          name={`payoutLimits.limitCount`}
        />
        <FormikNumericField
          required
          label={t('fields.payout_limit_sum')}
          name={`payoutLimits.limitSum`}
        />
      </Fragment>
    ),
    [t],
  );

  const onBeforeUpdateMutation = useCallback(
    ({
      id,
      data,
    }: {
      id: string;
      data: Values;
    }): { id: string; data: Values } => ({
      id,
      data: omit(data, ['type', 'assetCurrencyId']),
    }),
    [],
  );

  return (
    <EntityDetailsPage
      title={title}
      id={id!}
      api={p2pProvidersApi}
      apiAsRole={false}
      listUrl={listUrl}
      initialValues={initialValues}
      validationSchema={validationSchema}
      queryKey={QueryKey.P2PProviders}
      formClassName="tw-max-w-md"
      initialValuesSelect={initialValuesSelect}
      onBeforeUpdateMutation={onBeforeUpdateMutation}
    >
      {({
        formik,
        queryResult,
      }: EntityDetailsPageChildrenProps<Values, P2PProvider>) => (
        <Fragment>
          <Typography variant="h5">{t('details.common_details')}</Typography>
          <FormikTextField label={t('fields.name')} name="name" required />
          <FormikSelect
            label={t('fields.type')}
            name="type"
            options={providerTypeOptions}
            disabled={!!queryResult.data}
            required
          />
          <FormikNumericField
            label={t('fields.withdrawal_fee')}
            allowNegative={false}
            decimalScale={0}
            name="withdrawalFee"
            suffix={defaultAssetCurrency?.symbol}
            fullWidth
            required
          />
          <FormikSelect
            label={t('fields.fiatCurrencyId')}
            name="fiatCurrencyId"
            options={fiatCurrenciesOptions}
            onChange={() => handleFiatCurrencyChange(formik)}
            required
          />
          <FormikSelect
            label={t('fields.assetCurrencyId')}
            name="assetCurrencyId"
            options={assetCurrenciesOptions}
            onChange={() => handleAssetCurrencyChange(formik)}
            disabled={!!queryResult.data}
            required
          />
          {formik.values.assetCurrencyId &&
            defaultAssetCurrency?.id !== formik.values.assetCurrencyId && (
              <FormikSelect
                label={t('fields.currency_exchange')}
                helperText={t('fields.currency_exchange_description')}
                name="currencyExchangeId"
                required
                options={getCurrencyExchangeOptions({
                  fiatCurrencyId: formik.values.fiatCurrencyId,
                  assetCurrencyId: formik.values.assetCurrencyId,
                })}
              />
            )}

          <P2PProviderConfig
            configType={OrderType.Payin}
            providerType={formik.values.type}
            assetCurrencyId={formik.values.assetCurrencyId}
            tradeMethods={formik.values.payinConfig?.tradeMethods}
            fiatCurrencyId={formik.values.fiatCurrencyId}
            onTradeMethodsChange={(event) =>
              handleTradeMethodsChange(event, formik, 'payinConfig')
            }
          />

          <P2PProviderConfig
            configType={OrderType.Payout}
            providerType={formik.values.type}
            assetCurrencyId={formik.values.assetCurrencyId}
            tradeMethods={formik.values.payoutConfig?.tradeMethods}
            fiatCurrencyId={formik.values.fiatCurrencyId}
            onTradeMethodsChange={(event) =>
              handleTradeMethodsChange(event, formik, 'payoutConfig')
            }
          />

          {renderPayoutLimits()}
        </Fragment>
      )}
    </EntityDetailsPage>
  );
};
