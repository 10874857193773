import { env } from 'config';
import { P2PProviderRequest } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/p2p-providers-payout-requests`;

export const p2pProvidersPayoutRequestsApi = {
  getForOrder: async (orderId: string): Promise<P2PProviderRequest[]> =>
    (await apiUtils.getWithQueryParams(`${url}/order/${orderId}`)).data,
};
