import moment from 'moment';
import { useMemo } from 'react';

import { AdditionalOrderColumn, OrdersTab, PayoutOrderStatus } from 'enums';
import { useQueryFilters } from 'hooks';
import { FilterDefinition, OrderFilters } from 'types';
import { orderUtils } from 'utils';

import { ManageOrdersList } from '../ManageOrderList';

export const AllOrders: React.FC = () => {
  useQueryFilters<OrderFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
    statuses: [PayoutOrderStatus.Completed, PayoutOrderStatus.Cancelled],
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      orderUtils.getStatusFilterDefinition(Object.values(PayoutOrderStatus)),
    ],
    [],
  );

  return (
    <ManageOrdersList
      tab={OrdersTab.All}
      additionalFilters={additionalFilters}
      additionalColumns={[
        AdditionalOrderColumn.TraderPayment,
        AdditionalOrderColumn.CustomerRequisites,
        AdditionalOrderColumn.Validation,
        AdditionalOrderColumn.StatusActive,
      ]}
    />
  );
};
