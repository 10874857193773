import { find, map } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect, FormikSelectProps, RequisitesInfo } from 'components';
import { DefaultPaymentTypeCode } from 'enums';
import { useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PaymentType, Requisites } from 'types';

type Props = {
  name: string;
  label?: string;
  requisites?: Requisites[];
  labelClassName?: string;
  fiatCurrencyId?: string;
  crossFiatCurrencyId?: string;
} & Partial<FormikSelectProps>;

export const RequisitesSelect: React.FC<Props> = ({
  name,
  requisites = [],
  label,
  labelClassName,
  fiatCurrencyId,
  crossFiatCurrencyId,
  ...rest
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.requisites_select',
  });
  const { paymentTypes } = useUserContext();

  const tsbpPaymentType = useMemo(
    () =>
      find(
        paymentTypes,
        (type: PaymentType) => type.code === DefaultPaymentTypeCode.TSBP,
      ),
    [paymentTypes],
  );

  const isDisabled = useCallback(
    (requisite: Requisites) => {
      if (requisite.paymentTypeId === tsbpPaymentType?.id) {
        return (
          !!fiatCurrencyId &&
          requisite.fiatCurrencyId !== fiatCurrencyId &&
          !!crossFiatCurrencyId &&
          requisite.fiatCurrencyId !== crossFiatCurrencyId
        );
      }
      return !!fiatCurrencyId && requisite.fiatCurrencyId !== fiatCurrencyId;
    },
    [crossFiatCurrencyId, fiatCurrencyId, tsbpPaymentType?.id],
  );

  const options = useMemo(
    () =>
      map(requisites, (requisite) => ({
        value: requisite.id,
        label: (
          <RequisitesInfo
            compact
            className={labelClassName}
            canCopy={false}
            fiatCurrencyId={requisite.fiatCurrencyId}
            bankId={requisite.bankId}
            cardInfo={requisite?.cardInfo}
            phone={requisite?.phone}
            paymentTypeId={requisite.paymentTypeId}
            cardholder={requisite.cardholder}
            swiftBic={requisite.swiftBic}
            taxId={requisite.taxId}
            bic={requisite.bic}
            email={requisite.email}
            idCard={requisite.idCard}
            beneficiaryName={requisite.beneficiaryName}
            accountNumber={requisite.accountNumber}
            name={requisite.name}
            expirationDate={requisite.expirationDate}
            paymentLink={requisite.paymentLink}
          />
        ),
        disabled: isDisabled(requisite),
      })),
    [requisites, labelClassName, isDisabled],
  );

  return (
    <FormikSelect
      noneOption
      label={label || t('label')}
      {...rest}
      name={name}
      options={options}
    />
  );
};
