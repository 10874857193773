import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { requisitesApi } from 'api';
import { CloseDialogResult, Dialog, FormikCheckbox } from 'components';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Requisites } from 'types';

type Values = {
  disableFutureVerification: boolean;
};

type Props = {
  open: boolean;
  requisites?: Requisites;
  onClose: (result: CloseDialogResult) => void;
};

export const ManagerRequisitesVerificationDialog: React.FC<Props> = ({
  open,
  requisites,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'requisites_verification_dialog',
  });

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        disableFutureVerification: Yup.boolean(),
      }),
    [],
  );

  const initialValues: Values = useMemo(
    () => ({
      disableFutureVerification: false,
    }),
    [],
  );

  const verifyMutation = useMutation<
    Requisites,
    AxiosError,
    { id: string; disableFutureVerification: boolean }
  >(requisitesApi.verify, {
    onSuccess: () => onClose({ ok: true }),
    notifierType: 'execute',
  });

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      if (requisites) {
        verifyMutation.mutate(
          {
            id: requisites.id,
            disableFutureVerification: values.disableFutureVerification,
          },
          {
            onSettled: () => {
              formikHelpers.setSubmitting(false);
            },
          },
        );
      }
    },
    [requisites, verifyMutation],
  );

  const handleClose = useCallback(
    (
      result: CloseDialogResult<{
        values: Values;
        formikHelpers: FormikProps<Values>;
      }>,
    ) => {
      if (result.ok) {
        result.data?.formikHelpers.submitForm();
      } else {
        verifyMutation.reset();
        result.data?.formikHelpers.resetForm();
        onClose({ ok: result.ok });
      }
    },
    [verifyMutation, onClose],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          mutation={verifyMutation}
          data={{ values: formik.values, formikHelpers: formik }}
          title={t('title')}
          okDisabled={!formik.isValid}
          onClose={handleClose}
        >
          <Form>
            <div className="tw-mb-4">{t('message')}</div>
            <div>
              <FormikCheckbox
                label={t('fields.disable_future_verification')}
                name="disableFutureVerification"
              />
            </div>
            <div className="tw-text-gray-500">
              {t('fields.disable_future_verification_helper')}
            </div>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
